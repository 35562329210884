import * as React from "react";
import {
  postMessageToParent,
  postMessageToZen,
} from "../../utilities/postMessage";
import { Container } from "../Container/Container";
import cookie from "cookiejs";

import "./Trigger.css";

const Trigger = () => {
  const [result, setResult] = React.useState<"success" | "failure">();
  const [isTransparentSubmission, setIsTransparentSubmission] =
    React.useState<boolean>(false);
  const [label, setLabel] = React.useState<"Confirm" | "Success" | "Failure">(
    "Confirm"
  );
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  React.useEffect(() => {
    handleResolutionCheck();
    transparentSubmission();
    window.addEventListener("message", (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.data.source === "zappa-protect-parent") {
        switch (event.data.payload.event) {
          case "challenge-result":
            if (event.data.payload.payload === "failure") {
              postMessageToParent("challenge-result", "failure");
            } else {
              setIsSubmitting(true);
              setResult(event.data.payload.payload);
              const resultLabel =
                event.data.payload.payload.charAt(0).toUpperCase() +
                event.data.payload.payload.slice(1);
              setLabel(resultLabel);
              setIsSubmitting(false);
            }
            break;

          case "challenge-reattempt":
            setIsSubmitting(true);
            setResult(event.data.payload.payload);
            const resultLabel =
              event.data.payload.payload.charAt(0).toUpperCase() +
              event.data.payload.payload.slice(1);
            setLabel(resultLabel);
            setIsSubmitting(false);
            break;
          case "present-challenge":
            cookie.set(
              `formInputData`,
              JSON.stringify(event.data.formInputData),
              { expires: 1 }
            );
            break;
        }
      }
    });
  }, []);

  const handleClick = (e: any) => {
    e.preventDefault();
    if (result == null) {
      if (!isTransparentSubmission) postMessageToParent("present-challenge");
      else postMessageToParent("challenge-result", "success");
    }
  };

  const handleResolutionCheck = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("mobile")) {
      setIsMobile((params.get("mobile") as string) === "true");
      cookie.set(`isMobile`, JSON.stringify(params.get("mobile") === "true"), {
        expires: 1,
      });
    }
  };

  const toggleResolutionClass = isMobile ? "--mobile" : "";

  const spanMessage = () => {
    let message = "I am not a robot";
    if (isMobile && result === "success") message = "Verification successful";
    if (isMobile && result === "failure") message = "Verification failed";
    if (!isMobile) return null;

    return <span className={toggleResolutionClass}>{message}</span>;
  };

  const transparentSubmission = () => {
    const ua = window.navigator.userAgent;
    // to be included
    const mouseyData = "";
    const zenResult = postMessageToZen(ua);
    //Mocked Success or fail from the Zen Engine right now set to false
    setIsTransparentSubmission(zenResult);
  };

  return (
    <Container view={"start"} mobile={isMobile}>
      {!isSubmitting && (
        <div
          className={"zp-trigger-wrapper" + toggleResolutionClass}
          data-testid="zp-trigger-wrapper"
        >
          <div id="notabot" className={toggleResolutionClass}>
            {!isMobile ? <span>I am not a robot</span> : null}
            <div id="checkbox" onClick={handleClick}>
              <input type="checkbox" id="_checkbox" />
              <label
                data-testid="checkbox-label"
                htmlFor="_checkbox"
                className={toggleResolutionClass + " " + result}
              >
                {isMobile ? (
                  <div id="status_mark"></div>
                ) : (
                  <div id="status">{label}</div>
                )}
              </label>
            </div>
            {spanMessage()}
          </div>

          {isMobile && (
            <div className={"horizontal_line" + toggleResolutionClass}></div>
          )}
          {!isMobile && <div className="vertical_line"></div>}
          <div id="brand" className={"zp-brand-box" + toggleResolutionClass}>
            <div className="logo-wrapper">
              <img
                src="https://zappa-assets.s3.us-east-2.amazonaws.com/logo-zappa-protect-white-color-line-sm.png"
                alt="Zappa Protect Logo"
                className={toggleResolutionClass}
              />
            </div>
            {!isMobile && (
              <>
                <div className="horizontal_line" />
                <span>
                  <a
                    href="https://www.zappaprotect.com/terms"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    Terms
                  </a>
                  {" | "}
                  <a
                    href="https://www.zappaprotect.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    Privacy
                  </a>
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

export default Trigger;
