import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Container } from "../Container/Container";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Instructions from "../Instructions/Instructions";
import "./Challenge.css";
import {
  Client,
  IAdblockDetect,
  IDevicePrint,
  IReportData,
} from "@zappahq/core";
import { postMessageToParent } from "../../utilities/postMessage";
import cookie from "cookiejs";

type IDateTags = {
  captcha_init: Date | null;
  captcha_key_verified: Date | null;
  captcha_render: Date | null;
  captcha_result_processing: Date | null;
  captcha_result_completed: Date | null;
  captcha_result_presented: Date | null;
};

const APIBaseURL = process.env.REACT_APP_API_BASE_URL;
// Lazy load each challenge into an array
const Challenges = {
  button: lazy(() => import("../challenges/Button/Button")),
  tracedline: lazy(() => import("../challenges/Tracedline/Tracedline")),
  puzzle: lazy(() => import("../challenges/Puzzle/Puzzle")),
  palmverify: lazy(() => import("../challenges/PalmVerify/PalmVerify")),
  poke: lazy(() => import("../challenges/Poke/Poke")),
  faceverify: lazy(() => import("../challenges/FaceVerify/FaceVerify")),
};

// Dynamically infer challenges based on key in `Challenges` object
type IChallenge = {
  challenge: keyof typeof Challenges;
  instructions: string;
  reattempt?: boolean;
};

type IPuzzles = [keyof typeof Challenges, keyof typeof Challenges];
type IInstructions = { [key: string]: string };
type AnalyzeFn = (data: any) => Promise<Response>;

let defaultInstructions: IInstructions = {
  button: "Click the Button",
  tracedline: "Drag the sliders so they intersect the blue line",
  puzzle: "Drag the highlighted image into the white placeholder section",
  palmverify: "show your palm to the camera",
  poke: "using the camera, point to the spot on the screen with your finger",
  faceverify: "show your face to the camera",
};

function Challenge({ reattempt = false }: IChallenge) {
  const params = new URLSearchParams(window.location.search);
  const [key, setKey] = React.useState<string>(
    params.get("key")?.toString() || ""
  );
  const [ip, setIP] = React.useState<string>(
    params.get("publicIp")?.toString() || ""
  );
  const [record, setRecord] = React.useState<string>(
    params.get("record")?.toString() || ""
  );
  const [isMobile] = React.useState<boolean>(
    params.get("mobile")?.toString() === "true" || false
  );
  const [puzzles, setPuzzles] = React.useState<IPuzzles>();
  const [instructions, setInstructions] = React.useState<IInstructions>({});
  // const [threshold, setThreshold] = React.useState<number>()//currently does nothing.
  const [zpClient, setZpClient] = React.useState<Client>();

  const adblockDetected = useRef<IAdblockDetect | null>(null); //useRef used because useState does not update value till next render.
  const devicePrint = useRef<IDevicePrint | null>(null);
  const fetched = useRef(false);
  const [dateTags, setDateTags] = React.useState<IDateTags>({
    captcha_init: new Date(),
    captcha_key_verified: null,
    captcha_render: null,
    captcha_result_processing: null,
    captcha_result_completed: null,
    captcha_result_presented: null,
  });
  const beforeUnloadHandler = React.useRef<(data: Event) => string | null>(
    () => null
  );

  let challengeTags: any = {}; //need to use global because useState does not update on time for submitData() to use it.

  const postSuccess = (data: any) => {
    postMessageToParent("challenge-record", `${data.id}`);
    postMessageToParent("challenge-result", "success");
  };

  const postFailure = (attempt: number) => {
    if (attempt == 1) postMessageToParent("challenge-result", "failure");
    else postMessageToParent("challenge-reattempt", "failure");
  };

  const postFailedZen = (data: any) => {
    postMessageToParent("challenge-record", `${data.id}`);
    postMessageToParent("challenge-reattempt", "failure");
  };

  const siteVerify = ({
    id,
    challenge_key,
  }: {
    id: string;
    challenge_key: string;
  }) => {
    return new Promise<Response>((resolve, reject) => {
      fetch(`${APIBaseURL}/api/zen/siteverify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          challenge_key: challenge_key,
        }),
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const analyze = (data: any) => {
    return new Promise<Response>((resolve, reject) => {
      fetch(`${APIBaseURL}/analyze`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const beaconAnalyze = (data: any) => {
    navigator.sendBeacon(`${APIBaseURL}/analyze`, JSON.stringify(data));
    return Promise.resolve(new Response());
  };

  interface submittedData {
    id: string;
    passed: boolean;
    retry?: boolean;
  }

  const submitData = (analyzeFn: AnalyzeFn = analyze) => {
    unbindAbandonEvent();
    return new Promise<submittedData>((resolve, reject) => {
      const fail_1 = cookie.get("challenge_1_fail") as string;
      const fail_2 = cookie.get("challenge_2_fail") as string;
      const form_data = cookie.get("formInputData") as string;
      cookie.remove("challenge_1_fail");
      cookie.remove("challenge_2_fail");
      challengeTags = {
        ...challengeTags,
        captcha_challenge_1_fail: fail_1 ? fail_1 : null,
        captcha_challenge_2_fail: fail_2 ? fail_2 : null,
      };
      let Zendata = {
        id: record,
        challenge_key: key,
        device_print: devicePrint.current,
        adblock: adblockDetected.current?.result,
        mousey: getTrackingData(),
        form_data,
        ip: ip,
        ...dateTags,
        ...challengeTags,
      };
      analyzeFn(Zendata)
        .then((res) => {
          if (res.ok)
            setTimeout(() => {
              siteVerify({
                id: Zendata.id,
                challenge_key: Zendata.challenge_key,
              })
                .then((verify) => verify.json())
                .then((verify) => {
                  resolve({
                    id: Zendata.id,
                    passed: verify.passed,
                    retry: false,
                  });
                });
            }, 1000);
          else
            reject({
              id: Zendata.id,
              passed: false,
              retry: false,
            });
        })
        .catch(() =>
          reject({
            id: Zendata.id,
            passed: false,
            retry: true,
          })
        );
    });
  };

  const setChallengeResult = (result: "success" | "failure", puzzle: 1 | 2) => {
    if (result === "failure") {
      postFailure(puzzle);
    } else {
      challengeTags = {
        ...challengeTags,
        [`captcha_challenge_${puzzle}_complete`]: new Date(),
      };
      submitData()
        .then((result) => {
          if (result.passed) postSuccess(result.id);
          else postFailedZen(result);
        })
        .catch(() => {
          postFailure(puzzle);
        });
    }
  };

  React.useEffect(() => {
    setZpClient(new Client(key));
  }, []);

  React.useEffect(() => {
    if (zpClient) {
      startdevicePrint().then((data) => (devicePrint.current = data));
      startTracking();
      zpClient
        .GetDevicePrint()
        .then((data: IDevicePrint) => {
          devicePrint.current = data;
        })
        .then(() => {
          zpClient.GetAdblockDetect().then((adData: IAdblockDetect) => {
            adblockDetected.current = adData;
          });
        })
        .catch((err: any) => {
          console.error(err);
        });
    }

    return () => {
      stopTracking();
    };
  }, [zpClient]);

  const keyCheck = ({ key, domain }: { key: string; domain: string }) => {
    return new Promise((resolve, reject) => {
      fetch(`${APIBaseURL}/api/sites/domainkeycheck`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Key: key,
          Domain: domain,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.success);
        })
        .catch((err) => reject(err));
    });
  };

  useEffect(() => {
    if (!fetched.current) {
      //only here to allow zen to load test
      const servePuzzles: IPuzzles =
        String(process.env.BITBUCKET_BRANCH).toUpperCase() === "DEVELOP" &&
        key === "zentest"
          ? ["button", "button"]
          : ["puzzle", "puzzle"];
      let tempInstructions: IInstructions = {};
      servePuzzles.forEach((puzzle) => {
        if (!tempInstructions[puzzle])
          tempInstructions[puzzle] = defaultInstructions[puzzle];
      });
      keyCheck({ key, domain: window.document.referrer })
        .then((res) => {
          if (res) {
            if (!dateTags.captcha_key_verified)
              setDateTags({ ...dateTags, captcha_key_verified: new Date() });
            setPuzzles(servePuzzles);
            setInstructions(tempInstructions);
            fetched.current = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [key]);

  const startTracking = (): void => {
    if (!zpClient) {
      return;
    }
    zpClient.StartTracking({});
  };

  const stopTracking = (): void => {
    if (!zpClient) {
      return;
    }
    zpClient.StopTracking();
  };

  const getTrackingData = (): IReportData | null => {
    if (!zpClient) {
      return null;
    }
    return zpClient.GetTrackingData();
  };

  const startdevicePrint = (): Promise<IDevicePrint | null> => {
    if (!zpClient) {
      return Promise.resolve(null);
    }
    return new Promise((resolve) => {
      zpClient?.GetDevicePrint().then((data) => resolve(data));
    });
  };

  const bindAbandonEvent = () => {
    unbindAbandonEvent();
    beforeUnloadHandler.current = (event: Event) => {
      event.preventDefault();
      submitData(beaconAnalyze);
      return null;
    };
    window.addEventListener("unload", beforeUnloadHandler.current);
  };

  const unbindAbandonEvent = () => {
    window.removeEventListener("unload", beforeUnloadHandler.current);
  };

  const renderChallenge = () => {
    if (fetched.current) {
      bindAbandonEvent();
      const SelectedChallenge =
        Challenges[puzzles![reattempt ? 1 : (0 as number)]];
      challengeTags = { ...challengeTags, captcha_render: new Date() };
      return (
        <div className="flex items-center justify-center w-full h-full gap-2">
          <Container view="instructions" bgcolor="#fff" mobile={isMobile}>
            <Instructions
              instructions={
                instructions[puzzles![reattempt ? 1 : (0 as number)]]
              }
              reattemt={reattempt}
            />
          </Container>
          <SelectedChallenge
            setResult={setChallengeResult}
            challenge_key={key}
            reattempt={reattempt}
            canvasSize={isMobile ? 300 : 400}
            containerSize={isMobile ? 300 : 400}
          />
        </div>
      );
    } else return <LoadingComponent status="loading" />;
  };

  return (
    <Container view="challenge" bgcolor="#fff">
      <div
        id="zp-challenge-container"
        className="flex items-center justify-center w-full h-full"
      >
        <Suspense fallback={<LoadingComponent status="loading" />}>
          {renderChallenge()}
        </Suspense>
      </div>
    </Container>
  );
}

export { Challenge };
