import * as React from "react";
import { Container } from "../Container/Container";
import Head from "../Head/Head";
import "./Instructions.css";
export interface InstructionsProps
  extends React.HTMLAttributes<HTMLDivElement> {
  instructions?: string;
  reattemt?: boolean;
  mobile?: "--mobile" | "";
}

const Instructions: React.FC<InstructionsProps> = ({
  instructions = "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  reattemt = false,
}) => {
  const editedInstructions = () => (
    <span data-testid="second-attempt-span">
      <b>*** Second Attempt *** </b>
      {instructions}
    </span>
  );
  return (
    <Container
      data-testid="instructions-container"
      view="instructions"
      bgcolor="transparent"
    >
      <div className="Instructions">
        <Head />
        <div
          data-testid="instructions_content"
          className="Instructions_content"
        >
          {reattemt ? editedInstructions() : instructions}
        </div>
        <div
          data-testid="instructions_divider"
          className="Instructions_divider"
        ></div>
      </div>
    </Container>
  );
};

export default Instructions;
